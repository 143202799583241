<template>
  <Header />
  <main class="bg-gray-200">
    <div class="section section-lg pt-5 pt-md-7">
      <div class="container">
        <div class="row pt-5 pt-md-0">
          <Menu />
          <MenuMini />
          <div class="col-12 col-lg-9">
            <div class="card border-gray-300 p-0 p-md-4 mb-4">
              <div class="card-body">
                <h3 class="h5 mb-0">Notifications</h3>
                <ul class="list-group list-group-flush mt-5">
                  <li
                    class="list-group-item d-flex align-items-center justify-content-between rounded-0 ps-0 py-3"
                  >
                    <div>
                      <h3 class="h6 mb-1">Join Mailing List</h3>
                      <p>Get the latest news, promotion, and service updates</p>
                    </div>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="joinMailList"
                        checked
                        disabled
                      />
                      <label
                        class="form-check-label"
                        for="joinMailList"
                      ></label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <Footer />
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Menu from '@/components/Menu'
import MenuMini from '@/components/MenuMini'

export default {
  components: {
    Header,
    Menu,
    MenuMini,
    Footer,
  },
}
</script>
